import React from "react"
import "./index.scss"
import WebpImage from "../WebpImage";

const NewSkyhyCta = () => {
  return (
    <div className="new-skyhy-cta">
      <div className="cards-container">
        <a href="/contact-lenses/skyhy-by-hubble-lenses" className="cta-card">
          <WebpImage wrapperClassName="image" fileName="PCP/SkyHy-PCP-CTA.jpg"/>
          <div className="button">Shop SkyHy by Hubble Daily</div>
        </a>
        <a href="/contact-lenses/skyhy-monthly-by-hubble-lenses" className="cta-card">
          <WebpImage wrapperClassName="image" fileName="PCP/SkyHy-Monthly-PCP-CTA.png"/>
          <div className="button">Shop SkyHy by Hubble Monthly</div>
        </a>
      </div>

      <div className="description-container">
        <span className="subheader">the most breathable</span>
        <h2 className="header"><b>Sky</b>Hy by Hubble<sup>®</sup> Collection</h2>
        <p className="body">
          All the comfort and breathability of SkyHy by Hubble is now available as a monthly lens to fit the wear schedule that’s right for you. SkyHy by Hubble Monthly contact lenses boast the latest in contact lens technology with their premium silicone hydrogel material, at an affordable price for 6 months of daily wear.
        </p>
      </div>
    </div>
  )
}

export default NewSkyhyCta
